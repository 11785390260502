<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo />
        <!-- End Header Area -->
        <!-- Start Slider Area  -->
        <div class="rn-slider-area poss_relative" id="invitation">
            <div class="slide personal-portfolio-slider slider-style-3 slider-paralax bg_image bg_image--25 d-flex align-center">
                <BannerThree>
                    <span v-if="partyName != ''" slot="designation">Hey {{partyName}}, you're invited!</span>
                    <h1 class="heading-title custom-font" slot="heading-title">
                        jess + josh
                    </h1>
                    <span slot="underspot">2-2-2024</span>
                </BannerThree>
            </div>
        </div>
        <!-- End Slider Area -->
        <!-- Start About Area  -->
        <div class="section about-area rn-section-gap bg_color--1" id="about" align="center">
            <div class="about-wrapper" align="center">
                <v-container align="center">
                    <v-row>
                        <v-col lg="12">
                            <div class="section-title service-style--3 text-center mb--20 mb_sm--0 md_md--0">
                                <p>
                                    We would love for you to celebrate with us on our special day as we make our vows and are wed in the presence of family and friends
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <InvitationDetails align="center">
                    </InvitationDetails>
                    <br /> <br />
                    <br /> <br />
                           <v-row>
                               <v-col lg="12">
                                   <div class="section-title service-style--3 text-center mb--20 mb_sm--0 md_md--0"  align="center">
                                       <p>
                                           <b>rsvp closed - please contact jess or josh</b>
                                           <br />                               <br /><br />
                                           The best gift we could ask for is your company at our wedding. However, should you wish to honour us with a gift, we will have a wishing well available to go towards our honeymoon. <br /> <br />
                                           While we wish that we could include all children and plus ones, unfortunately due to venue capacity limits, we hope that you appreciate and understand that invitations are extended only to those named. <br /> <br />
                                           Should you need to be in contact, please reach out to either of us via social media or through return email.
                                       </p>
                                   </div>
                               </v-col>
                           </v-row>
                </v-container>
            </div>
        </div>
        <FooterTwo />
    </div>
</template>

<script>
  import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo";
  import BannerThree from "../../components/slider/BannerThree";
  import InvitationDetails from "../../components/invitation/InvitationDetails";
  import ServiceThree from "../../components/service/ServiceThree";
  import PortfolioTwo from "../../components/portfolio/PortfolioFour";
  import Blog from "../../components/blog/Blog";
  import Contact from "../../components/contact/Contact";
  import FooterTwo from "../../components/footer/FooterTwo";
  import axios from "axios";
  import { guidv4, encodeParty, decodeParty, decodeAttendee } from "../../components/Utility/Utility.js";
  import qs from 'qs';

  export default {
    components: {
      HeaderOnePageTwo,
      BannerThree,
      InvitationDetails,
      ServiceThree,
      PortfolioTwo,
      Blog,
      Contact,
      FooterTwo,
    },
    data() {
        return {
           formData: {
                    action: "",
                    token: "",
                    data: ""
            },
            partyName: ""
        };
        },
        mounted() {
            const urlParams = new URLSearchParams(window.location.search);
            this.partyId = urlParams.get('party');
            if (this.partyId != null) {
                this.formData.action = 'getPartyName';
                this.formData.data = this.partyId;
                axios({
                    method: 'POST',
                    url: 'https://api.jessplusjosh.com',
                    data: qs.stringify(this.formData)
                })
                    .then(response => {
                        var _party = response.data;
                        decodeParty(_party);
                        this.partyName = _party.partyName;
                    })
                    .catch(error => {
                        console.error(error);
                });
            }
        }
  };
</script>

<style lang="scss">
  .feather-menu {
    color: #1d1d24;
  }

</style>
