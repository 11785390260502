export const guidv4 = (data = null) => {
    // Generate 16 bytes (128 bits) of random data or use the data passed into the function.
    if (!data) {
        data = new Uint8Array(16);
        window.crypto.getRandomValues(data);
    }

    // Set version to 0100
    data[6] = data[6] & 0x0f | 0x40;
    // Set bits 6-7 to 10
    data[8] = data[8] & 0x3f | 0x80;

    // Convert to hexadecimal string
    const hex = Array.from(data)
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');

    // Output the 36 character UUID.
    return hex.substring(0, 8) + '-' +
        hex.substring(8, 12) + '-' +
        hex.substring(12, 16) + '-' +
        hex.substring(16, 20) + '-' +
        hex.substring(20);
};

export const encodeParty = (party) => {
    party.id = encodeURIComponent(party.id);
    party.partyName = encodeURIComponent(party.partyName);
    party.attendees = encodeURIComponent(party.attendees);

    // encode attendeeIn
    if (party.attendeesIn != undefined) {
        for (var i = 0; i < party.attendeesIn.length; i++) {
            encodeAttendee(party.attendeesIn[i]);
        };
    }
                
    // encode attendeeOut
    if (party.attendeesOut != undefined) {
        for (var i = 0; i < party.attendeesOut.length; i++) {
            
            encodeAttendee(party.attendeesOut[i]);
        };
    }
};

export const decodeParty = (party) => {
    party.id = decodeURIComponent(party.id);
    party.partyName = decodeURIComponent(party.partyName);
    party.attendees = decodeURIComponent(party.attendees);

    if (party.attendeesIn != undefined) {
        for (var i = 0; i < party.attendeesIn.length; i++) {
            decodeAttendee(party.attendeesIn[i]);
        };
    }
               
    if (party.attendeesOut != undefined) {
        for (var i = 0; i < party.attendeesOut.length; i++) {
            decodeAttendee(party.attendeesOut[i]);
        };
    }
};

export const decodeAttendee = (attendee) => {
    attendee.name = decodeURIComponent(attendee.name);
    attendee.dietaryRequirements = decodeURIComponent(attendee.dietaryRequirements);
    attendee.song = decodeURIComponent(attendee.song);
    attendee.transportationRequirement = decodeURIComponent(attendee.transportationRequirement);
    if (attendee.isAttending === 0) {
        attendee.isAttending = false;
    }

    if (attendee.isAttending === 1) {
        attendee.isAttending = true;
    }
};

export const encodeAttendee = (attendee) => {
    attendee.name = encodeURIComponent(attendee.name);
    attendee.dietaryRequirements = encodeURIComponent(attendee.dietaryRequirements);
    attendee.song = encodeURIComponent(attendee.song);
    attendee.transportationRequirement = encodeURIComponent(attendee.transportationRequirement);

    if (attendee.isAttending === 0) {
        attendee.isAttending = false;
    }

    if (attendee.isAttending === 1) {
        attendee.isAttending = true;
    }
};

export const decodeSearchAttendee = (attendee) => {
    attendee.name = decodeURIComponent(attendee.name);
    attendee.partyMembers = decodeURIComponent(attendee.partyMembers);
    attendee.partyName = decodeURIComponent(attendee.partyName);
};

export const decodeAttendeeConfirmation = (attendee) => {
    attendee.name = decodeURIComponent(attendee.name);
    attendee.dietaryRequirements = decodeURIComponent(attendee.dietaryRequirements);
    attendee.song = decodeURIComponent(attendee.song);
    attendee.transportationRequirement = decodeURIComponent(attendee.transportationRequirement);

    if (attendee.isAttending === 0) {
        attendee.isAttending = false;
    }

    if (attendee.isAttending === 1) {
        attendee.isAttending = true;
    }

    if (attendee.members != undefined) {
        for (var i = 0; i < attendee.members.length; i++) {
            decodeAttendee(attendee.members[i]);
        };
    }
};